// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import {useSiteMetadata} from '../hooks';

const NotFoundTemplate = () => {
    const {title, subtitle} = useSiteMetadata();

    return (
        <Layout title={`Not Found - ${title}`} description={subtitle}>
            <Sidebar/>
            <Page title="NOT FOUND">
                <p>Sorry, the page you're looking for is not here.</p>
                <p>It might be a old link for the old version of the blog, that it is not available anymore. I'm trying
                    to avoid this kind of errors, since <a href="https://www.w3.org/Provider/Style/URI">cool URIs do not
                        change</a>, but I might have forgot some of them. Please, search for the desired content on the
                    main page.</p>
            </Page>
        </Layout>
    );
};

export default NotFoundTemplate;
